<template>
  <div>
    <el-page-header :content="$t('fence.device')+' ['+imei+'] '+$t('fence.title')" @back="goBack"
                    style="margin-bottom:10px"></el-page-header>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <!-- <el-descriptions title="用户信息" :column="2">
            <el-descriptions-item label="设备型号 :">{{device.type}}</el-descriptions-item>
            <el-descriptions-item label="Imei :">{{device.imei}}</el-descriptions-item>
            <el-descriptions-item label="设备名称 :">{{device.name}}</el-descriptions-item>
            <el-descriptions-item label="当前状态 :">{{device.online}}</el-descriptions-item>
          </el-descriptions> -->
          <h4>{{ $t('fence.userinfo') }}</h4>
          <div class="userMes">
            <p><span>{{ $t('index.device_type') }} ：</span>{{ device.type }}</p>
            <p><span>IMEI ：</span>{{ device.imei }}</p>
            <p><span>{{ $t('index.device_name') }} ：</span>{{ device.name }}</p>
            <p><span>{{ $t('index.online_status') }} ：</span>{{ device.online }}</p>
          </div>
        </div>
      </template>
      <el-button @click="newFence">{{
          $t('fence.add')
        }}
      </el-button>
      <div class="fencelist">
        <el-table :data="fenceData" border stripe style="width: 100%">
          <el-table-column :label="$t('fence.name')" prop="name"></el-table-column>
          <el-table-column :label="$t('fence.radius')" prop="radius"></el-table-column>
          <el-table-column :label="$t('fence.alarm')" prop="alarm"></el-table-column>
          <el-table-column :label="$t('device.operating')">
            <template #default="scope">
              <el-button @click="handleEdit(scope.row.id)" size="small">{{ $t('device.edit') }}
              </el-button>
              <el-button @click="handleRemove(scope.row.id)" size="small">{{ $t('device.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            :page-size="pageSize"
            :page-sizes="[10, 20,50]"
            :total="pageTotal"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import fence from "@/model/fence";
import tool from "../../utils/tool";

export default {
  name: "dataFence",
  data() {
    return {
      imei: this.$route.query.imei,
      currentPage: "1",
      pageSize: 10,
      pageTotal: 0,
      fenceData: [],
      device: {}
    };
  },
  async mounted() {
    this.device = JSON.parse(tool.getLocalStorage("fence_device"));
    await this.fenceList();
  },

  methods: {
    async fenceList() {
      const ret = await fence.list({
        source: 2,
        imei: this.imei,
        page: this.currentPage,
        size: this.pageSize
      });
      if (ret.code === 200) {
        this.fenceData = ret.data.list;
        this.pageTotal = ret.data.count;
      }
    },
    newFence() {
      this.$router.push({
        path: "/group/new_fence",
        query: {
          imei: this.imei,
          source: 2,
          id: 0
        }
      });
    },
    handleEdit(id) {
      this.$router.push({
        path: "/group/new_fence",
        query: {
          imei: this.imei,
          source: 2,
          id: id
        }
      });
    },
    handleRemove(id) {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await fence.remove({
              id: id
            });
            if (ret.code === 200) {
              this.$message.success(this.$t('device.delete_success'));
              this.fenceList();
            } else {
              this.$message.error(ret.message);
            }
          })
          .catch(() => {
          });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fenceList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fenceList();
    },
    goBack() {
      this.$router.replace({
        path: "/data/home",
        query: {
          imei: this.imei
        }
      })
    }
  }
};
</script>
<style scoped>
.fencemes ul {
  height: 60px;
  display: flex;
}

.fencemes ul li {
  width: 25%;
}

.fencelist {
  margin-top: 30px;
}

.demo-form-inline {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}

.card-header h4 {
  font-size: 16px;
  margin-left: 10px;
  line-height: 60px;
  font-weight: bold;
}

.userMes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.userMes p {
  width: 50%;
  height: 40px;
  line-height: 40px;
  text-indent: 10px;
}
</style>
